/*
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-10-29 10:46:45
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-10-29 11:05:29
 * @FilePath: /mediatom-web/src/viewsForManage/reportdatas/SynthReport/SynthTable/skipMixin.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
  methods: {
    skipToConfig (from, row, groupByList) {
      const hasPublisher = groupByList.find((item) => (item) === 'publisher_id')
      const hasPlace = groupByList.find((item) => (item) === 'place_id')
      const hasDsp = groupByList.find((item) => (item) === 'dsp_place_id')
      // const query = {}
      // id: row.id,
      // dspName: row.name,
      // platAccountName: row.platAccountName,
      // platName: row.platName,
      // settleRatio: row.settleRatio,
      // platPlaceId: row.platPlaceId,
      // sourceType: row.sourceType
      // if (hasDsp) {
      //   query.
      // }

      if (hasDsp && !hasPublisher && !hasPlace) {
        // 只有广告源
        this.$router.push({
        })
      } else if (hasPublisher && !hasPlace && hasDsp) {
        // 广告源 + 供应商
        this.$router.push({
        })
      } else if (hasPublisher && hasPlace && hasDsp) {
        // 广告源 + 供应商 + 广告位
        this.$router.push({
        })
      } else if (hasPublisher && !hasPlace && !hasDsp) {
        // 供应商
        this.$router.push({
        })
      } else if (hasPublisher && hasPlace && !hasDsp) {
        // 供应商 + 广告位
        this.$router.push({
        })
      } else if (!hasPublisher && hasPlace && !hasDsp) {
        // 广告位
        this.$router.push({
        })
      }
    }
  }
}
